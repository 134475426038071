<template>
  <v-hover v-slot="{ hover }" :disabled="!editable">
    <v-card
      :to="id ? { name: 'SchoolsProfile', params: { id } } : ''"
      :max-width="width || '196'" 
      outlined
    >
      <v-card-text class="pa-1">
        <v-img v-if="url" :src="url" contain></v-img>
      </v-card-text>

      <v-fade-transition>
        <v-overlay
          v-if="hover"
          color="primary"
          absolute
        >
          <v-btn @click="$emit('delete')" icon>
            <v-icon color="error">mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-overlay>
      </v-fade-transition>
    </v-card>
  </v-hover>
</template>

<script>
import firebase from 'firebase'

export default {
  props: {
    filename: String,
    editable: Boolean,
    id: String,
    width: String,
  },

  data() {
    return {
      url: null,
    }
  },

  watch: {
    'filename': function () {
      this.url = null
      this.getUrl()
    },
  },

  methods: {
    getUrl() {
      var storage = firebase.storage()

      storage.ref(`schoollogos/${this.filename}`).getDownloadURL()
      .then(url => {
        this.url = url
      })
      .catch(error => {
        console.log(error.message)
      })
    }
  },

  created() {
    this.url = null
    this.getUrl()
  }
}
</script>