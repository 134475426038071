<template>
  <div class="flex-grow-1">
    <!-- Filters -->
    <div v-if="!status.getting && !$store.state.job.status.getting">
      <v-row>
        <v-col class="profile-details">
          <preloader v-if="!job && status.getting" />
          
          <div v-if="job" class="d-flex flex-column">
            <div class="d-flex align-center mt-0 mt-sm-4 justify-space-between" :class="{ 'flex-wrap' : $vuetify.breakpoint.xs }">
              <div class="d-flex align-center">
                <v-btn
                  @click="$router.go(-1)"
                  class="rounded mr-5"
                  color="grey lighten-2"
                  outlined
                  x-small
                  fab
                >
                  <v-icon size="25" color="dark">mdi-chevron-left</v-icon>
                </v-btn>

                <h2 class="text-h4 font-weight-black text-capitalize">
                  <span>{{ job.title }}</span>
                </h2>
              </div>

              <FavouriteJob v-if="!$store.getters['jobs/isOwner'](job)" :job="job"/>

              <div 
                v-if="$store.getters['jobs/isOwner'](job)"
                :class="{ 'full-width' : $vuetify.breakpoint.xs }"
                class="d-flex gap-10 mt-5 mt-sm-0"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :to="{ name: 'JobUpdate', params: { id: job.id } }"
                      v-on="on"
                      icon
                      small
                    >
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                  </template>
                  <span class="caption">Edit</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="confirmDelete()" v-on="on" icon small>
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>
                  <span class="caption">Delete</span>
                </v-tooltip>
                
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="duplicateJob(job)" v-on="on" icon small>
                      <v-icon>mdi-content-duplicate</v-icon>
                    </v-btn>
                  </template>
                  <span class="caption">Copy</span>
                </v-tooltip>
              </div>
            </div>
            
            <div class="d-flex mt-7" :class="{ 'flex-wrap' : $vuetify.breakpoint.xs }">
              <v-sheet v-if="school" max-width="200" :class="$vuetify.breakpoint.xs ? 'mx-auto mb-3' : 'mr-7'">
                <school-logo :filename="school.logo" :id="school.id" />

                <router-link
                  :to="{ name: 'SchoolsProfile', params: { id: school.id } }"
                  class="caption text-decoration-none underline-on-hover"
                >School Profile</router-link>
              </v-sheet>

              <div class="flex-grow-1">
                <!-- JOB POSITION -->
                <div v-if="job.position">
                  <label class="label-font heading-font">Teaching Level: </label> 
                  <span class="body-1">{{ Array.isArray(job.position) ? job.position.join(', ') : job.position }}</span>
                </div>
                
                <!-- SKILLSETS -->
                <span class="heading-font font-weight-bold mt-3">Key Skill Sets:</span>
                <div class="d-flex justify-space-between align-center mt-3 mb-3">
                  <div class="d-flex flex-wrap gap-7">
                    <v-chip
                      v-for="(skill, skillIndex) in job.skillSet"
                      :key="skillIndex"
                      small
                    >
                      {{ skill }}
                    </v-chip>
                  </div>
                </div>

                <!-- TYPE -->
                <div v-if="job.type" class="mb-1">
                  <label class="label-font heading-font">Job Type: </label> <span class="body-1">{{ job.type }}</span>
                </div>
                
                <!-- YEAR LEVEL -->
                <div v-if="job.yearLevel" class="mb-1">
                  <label class="label-font heading-font">School Level: </label> <span class="body-1">{{ job.yearLevel }}</span>
                </div>

                <!-- LOCALE -->
                <div v-if="school && school.locale" class="mb-1">
                  <label class="label-font heading-font">Locale: </label> <span class="body-1">{{ school.locale }}</span>
                </div>
                
                <!-- CONTRACT LENGTH -->
                <div v-if="job.contractLength" class="mb-1">
                  <label class="label-font heading-font">Contract Length: </label> <span class="body-1">{{ job.contractLength }}</span>
                </div>
                
                <!-- START DATE -->
                <div v-if="job.contractDates && job.contractDates.length" class="mb-1">
                  <label class="label-font heading-font">Contract Dates: </label>

                  <div class="d-flex flex-wrap gap-5 mt-1"> 
                    <v-chip
                      v-for="(range, i) in sortContractDates(job.contractDates)"
                      :key="i"
                      small
                    >{{ range[0] | simpleDate }} - {{ range[1] | simpleDate }}</v-chip>
                  </div>
                </div>

                <v-alert type="success" v-if="user && user.role == 'jobseeker' && !conflicts.length" border="left" text>
                  You don't have any availability conflicts.
                </v-alert>

                <v-alert 
                  v-if="user && user.role == 'jobseeker' && conflicts.length" 
                  type="warning" 
                  border="left" 
                  class="mt-5"
                  text
                >
                  <div class="mb-2">You have availablity conflict with the following {{ pluralize('date', conflicts.length) }}:</div>
                  <div>
                    {{ conflicts.join(' | ') }}
                  </div>

                  <div class="mt-3">
                    <v-btn @click="showAvailability = true"  color="primary" small>View Availability</v-btn>
                  </div>
                </v-alert>
              </div>
            </div>

            <v-divider class="mt-5"></v-divider>

            <!-- ADDRESS -->
            <div v-if="school" class="mt-5">
              <a :href="$mapLink(school.address.latitude, school.address.longitude)" class="text-decoration-none" target="_blank">
                <v-icon left>mdi-map-marker-outline</v-icon>
                <span>{{ [`${school.address.street_number || '' } ${school.address.route || ''}`, school.address.locality, school.address.administrative_area_level_1].join(', ') }}</span>
              </a>
            </div>

            <!-- DESCRIPTION -->
            <p class="mt-5 text-pre-line">
              {{ job.description }}
            </p>

            <!-- ATTACHMENTS -->
            <div class="mb-5 d-flex flex-wrap gap-5" v-if="job.attachments">
              <v-chip 
                v-for="(attachment, i) in job.attachments"
                :key="i"
                small
              >
                <span 
                  class="mr-2" 
                  :style="{ overflow: 'hidden', display: 'inline-block', textOverflow: 'ellipsis', maxWidth: 'calc(100% - 30px)' }"
                >{{ attachment | fileName }}</span>
                
                <v-btn 
                  @click="$downloadFile(`attachments`, attachment)"
                  color="grey" 
                  width="18" 
                  height="18" 
                  fab depressed
                >
                  <v-icon color="white" x-small>mdi-download</v-icon>
                </v-btn>
              </v-chip>
            </div>

            <!-- NOTES -->
            <div v-if="$store.getters['jobs/isOwner'](job)" class="mt-3">
              <div v-if="job.reference" class="mb-5">
                <span class="label-font heading-font">Ref#:</span> {{ job.reference }}
              </div>

              <v-card 
                v-if="job.notes && !editNote"
                color="yellow lighten-4"
                flat
              >
                <v-card-text class="pa-7">
                  <div class="font-weight-bold heading-font">
                    <span>
                      <v-icon left small>mdi-notebook</v-icon> Notes
                    </span>

                    <v-btn @click="editNote = true" icon small absolute right>
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </div>
                  <v-divider class="my-3"></v-divider>
                  <div class="text-pre-line">
                    {{ job.notes }}
                  </div>
                </v-card-text>
              </v-card>

              <custom-alert 
                v-if="$store.getters['jobs/isOwner'](job) && !job.notes && !editNote" 
                type="info" border="left" text
              >
                You don't have any notes for this job. Click <a class="text-decoration-underline" @click.prevent="editNote = true">here</a> to add notes.
              </custom-alert>

              <v-form 
                v-if="$store.getters['jobs/isOwner'](job) && editNote"
                ref="noteForm"
              >
                <v-textarea
                  v-model="job.notes"
                  placeholder="Jobe Note"
                  outlined
                ></v-textarea>

                <div>
                  <v-btn
                    @click="saveNote(job)"
                    :loading="jobsStatus.savingNote"
                    color="accent gradient"
                  >Save Note</v-btn>

                  <v-btn 
                    @click="editNote = false" 
                    :disabled="jobsStatus.savingNote"
                    class="ml-2" 
                    depressed
                  >Cancel</v-btn>
                </div>
              </v-form>
            </div>
            
            <div v-if="(user && user.role == 'jobseeker') && (job.question1 || job.question2 || job.question3)">
              <v-card class="mt-4 pa-2 shadow-lg">
                <v-card-title>
                  <div class="primary--text label-font heading-font mb-2">Application Questions: </div> 
                </v-card-title>
                <v-card-text>
                  <v-form>
                    <div v-if="job.question1">
                      <div :class="$text.label" class="mb-2">{{ job.question1 }}</div>
                      <p class="body-1" v-if="checkCurrentUserApplied.length"><span class="font-weight-black">Answer:</span> {{ checkCurrentUserApplied[0].answers.q1 }}</p>
                      <v-textarea
                        v-if="!checkCurrentUserApplied.length"
                        v-model="answer.q1"
                        :rules="[rules.required, rules.answer]"
                        counter="500"
                        rows="3"
                        auto-grow
                        outlined
                      ></v-textarea>
                    </div>
                    <div v-if="job.question2">
                      <p :class="$text.label" class="mb-2">{{ job.question2 }}</p>
                      <p class="body-1" v-if="checkCurrentUserApplied.length"><span class="font-weight-black">Answer:</span> {{ checkCurrentUserApplied[0].answers.q2 }}</p>
                      <v-textarea
                        v-if="!checkCurrentUserApplied.length"
                        v-model="answer.q2"
                        :rules="[rules.required, rules.answer]"
                        counter="500"
                        auto-grow
                        outlined
                        rows="3"
                      ></v-textarea>
                    </div>
                    <div v-if="job.question3">
                      <p :class="$text.label" class="mb-2">{{ job.question3 }}</p>
                      <p class="body-1" v-if="checkCurrentUserApplied.length"><span class="font-weight-black">Answer:</span> {{ checkCurrentUserApplied[0].answers.q3 }}</p>
                      <v-textarea
                        v-if="!checkCurrentUserApplied.length"
                        v-model="answer.q3"
                        :rules="[rules.required, rules.answer]"
                        label="Answer"
                        counter="500"
                        auto-grow
                        outlined
                        rows="3"
                      ></v-textarea>
                    </div>
                  </v-form>
                </v-card-text>
              </v-card>
            </div>

            <custom-alert v-if="hasQuestionnaire && !checkCurrentUserApplied.length && (user && user.role == 'jobseeker')" class="mt-5">
              Please make sure to answer all application questions before you apply for this job
            </custom-alert>

            <div v-if="user && user.role == 'jobseeker'">
              <div v-if="job.status == jobStatusArray.declined">
                <v-btn color="grey" class="gradient mt-12">
                  Job Is Already Closed
                </v-btn>
              </div>
              <div v-if="job.status == 'Open'">
                <v-btn
                  v-if="!checkCurrentUserApplied.length"
                  @click="applied()"
                  :loading="applicantStatus.applying"
                  :disabled="!validAnswers"
                  color="accent gradient"
                  class="mt-5"
                >
                  Apply for Job
                </v-btn>

                <v-btn
                  v-if="checkCurrentUserApplied.length"
                  class="mt-12"
                  depressed
                  disabled
                >
                  Job Applied
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>

        <!-- RIGHT SIDEBAR ------------------------------------------------------------------------------------------------------->
        <v-col class="d-none" cols="4" md="4">
          <preloader v-if="!job && status.getting" />
          <v-card v-if="school" max-width="300px" class="mt-5 mb-5 shadow-lg">
            <v-card-text>
              <preloader v-if="!schoolLogo" />

              <div>
                <router-link
                  :to="{ name: 'SchoolsProfile', params: { id: school.id } }"
                  class="subtitle-1 text-capitalize font-weight-bold text-decoration-none grey--text text--darken-3"
                >
                  <v-img
                    v-if="schoolLogo"
                    :src="schoolLogo"
                    max-height="300"
                    contain
                  ></v-img>
                </router-link>
              </div>

              <v-card-title class="d-flex flex-column pa-4 pt-12">
                <div class="d-flex flex-column mt-n5 mb-3">
                  <router-link
                    :to="{ name: 'SchoolsProfile', params: { id: school.id } }"
                    class="subtitle-1 text-capitalize font-weight-bold text-decoration-none grey--text text--darken-3"
                  >
                    {{ school.name }}
                  </router-link>
                </div>

                <router-link
                  :to="{ name: 'SchoolsProfile', params: { id: school.id } }"
                  class="primary--text caption"
                >School Profile</router-link>
              </v-card-title>
            </v-card-text>
          </v-card>

          <v-card v-if="viewApplicants && job" class="mt-3 shadow-lg">
            <v-card-text>
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>Status:</td>
                      <td>{{ job.status }}</td>
                    </tr>
                    <tr>
                      <td>Applicants:</td>
                      <td>
                        {{ $store.getters["apply/getApplicantsCount"](job.id) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-sheet
                v-if="job.status !== 'Closed' && $store.getters['apply/getApplicantObjects'](job.id).length"
                class="flex-grow-1 overflow-y-auto mt-5"
                max-height="300"
                width="100%"
              >
                <v-list-item
                  v-for="(item, index) in $store.getters['apply/getApplicantObjects'](job.id)"
                  :key="index"
                  :to="{ name: 'TalentProfile', params: { id: item.userid } }"
                  class="grey lighten-5 mb-1"
                  dense
                >
                  <v-list-item-icon class="mr-3">
                    <UserPhoto
                      :id="item.userid"
                      size="30"
                      photoSize="small"
                      class="rounded"
                      tile
                    />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $store.getters["users/getFullName"](item.userid) }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="caption grey--text ls-0">
                      {{ item.createdAt | fromNow }} ago
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-sheet>
              <v-btn
                v-if="$store.getters['apply/getApplicantsCount'](job.id) && job.status !== 'Closed'"
                color="accent gradient"
                class="mt-5"
                :to="{ name: 'ReviewApplicants', params: { id: job.id } }"
                block
                small
              >
                Award Job
              </v-btn>
            </v-card-text>
          </v-card>

          <v-card
            v-if="checkCurrentUserApplied.length"
            class="mt-3 shadow-lg"
          >
            <v-card-text>
              <div class="d-flex subtitle-1 text-capitalize">
                <div class="subtitle-1 text-center">
                  <span class="heading-font primary--text font-weight-bold mr-2">Status:</span>
                  <v-chip class="primary white--text" dense label small>
                    {{ checkCurrentUserApplied[0].status }}
                  </v-chip>
                </div>
              </div>
              <div v-if="job && job.status == 'Open'" class="text-center">
                <div v-if="checkCurrentUserApplied[0].contractFile != null">
                  <p
                    class="subtitle-1 text-center font-weight-bold text-center"
                  >
                    Review Your Contract
                  </p>
                </div>
                <div>
                  <v-btn
                    v-if="checkCurrentUserApplied[0].contractFile != null"
                    color="grey lighten-4 gradient py-8 px-2"
                    @click="getPDFurl(checkCurrentUserApplied[0])"
                  >
                    <v-icon size="45">mdi-file-pdf-box-outline</v-icon>
                  </v-btn>
                </div>
                <div
                  v-if="
                    checkCurrentUserApplied[0].status == 'Uploaded Contract'
                  "
                >
                  <v-btn
                    color="grey gradient"
                    class="mt-5 white--text"
                    dark
                    @click="
                      updateStatus({
                        applicant: checkCurrentUserApplied[0],
                        action: 'decline',
                      })
                    "
                  >
                    Decline This Job
                  </v-btn>
                  
                  <v-btn
                    color="accent gradient"
                    class="mt-5 mb-5"
                    dark
                    @click="
                      updateStatus({
                        applicant: checkCurrentUserApplied[0],
                        action: 'hire',
                      })
                    "
                  >
                    Accept This Offer
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- End Filters -->

    <!-- AVAILABILITY MODAL -->
    <v-dialog
      v-model="showAvailability"
      max-width="850px"
    >
      <v-card>
        <v-btn @click="showAvailability = false" icon absolute right top>
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-card-text>
          <Availability />

          <div class="mt-3">
            <v-btn @click="showAvailability = false" color="primary">Close</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- CONFIRM DIALOG -->
    <confirm-delete
      :show="deleteDialog"
      message="Are you sure you want to delete this job?"
      :deleting="jobsStatus.deleting"
      @cancel="closeDeleteDialog()"
      @confirmed="deleteConfirmed()"
    ></confirm-delete>

    <!-- ERROR DIALOG -->
    <v-dialog
      v-model="errorDialog"
      :max-width="$dialog.small"
    >
      <v-card>
        <v-card-text class="pa-5">
          <v-btn @click="errorDialog = false" class="mt-5 mr-n3" fab depressed absolute right top x-small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <custom-alert class="mb-0">
            *You must first complete your <router-link :to="{ name: 'MyProfile' }">profile</router-link> before applying for this job.
          </custom-alert>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import _filter from "lodash/filter"
import _find from "lodash/find"
import _reverse from "lodash/reverse"
import _omit from "lodash/omit"
import _orderBy from "lodash/orderBy"
import db from '@/firebase/init'
import firebase from "firebase"
import rules from "@/rules"
import "animate.css"
import pluralize from 'pluralize'
import Availability from '@/views/availability/Availability'
import SchoolLogo from '@/views/school/SchoolLogo'
import moment from 'moment'

export default {
  name: "JobProfile",

  metaInfo: {
    title: "Job Profile",
  },

  data() {
    return {
      showAvailability: false,
      deleteDialog: false,
      selectedList: null,
      errorDialog: false,
      editNote: false,
      queryTxt: null,
      answer: {},
      pluralize,
      rules,
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    applicants: function (item) {
      if (item.length) {
        this.searchUser()
      }
    },

    '$route.params.id': function () {
      this.loadJobData()
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    Availability,
    SchoolLogo
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      profile: (state) => state.profile.profile,
      users: (state) => state.users.users,
      job: (state) => state.job.selectedJob,
      jobApplied: (state) => state.apply.applied,
      jobFavorite: (state) => state.job.jobFavorite,
      school: (state) => state.job.selectedSchool,
      schoolLogo: (state) => state.job.schoolLogo,
      status: (state) => state.job.status,
      jobStatusArray: (state) => state.jobs.jobStatus,
      jobsStatus: (state) => state.jobs.status,
      applicants: (state) => state.apply.allApplicants,
      applicantStatus: (state) => state.apply.status,
      availabilities: (state) => state.availability.userAvailability,
    }),

    viewApplicants() {
      let canView = false
      if (this.user) {
        if (this.user.role == "admin") {
          canView = true
        } else {
          if (this.job) {
            let currentUser = firebase.auth().currentUser
            canView = currentUser.uid == this.job.useruid ? true : false
          }
        }
      }
      return canView
    },

    checkCurrentUserApplied() {
      if (this.job) {
        let currentUser = firebase.auth().currentUser
        let data = _filter(this.jobApplied, ["userid", currentUser.uid])
        return _filter(data, ["jobId", this.job.id])
      } else {
        return false
      }
    },
    isFavorite() {
      let currentUser = firebase.auth().currentUser
      if (this.jobFavorite) {
        return this.jobFavorite.userid == currentUser.uid
      } else {
        return false
      }
    },

    hasQuestionnaire: function () {
      if (this.job.question1 || this.job.question2 || this.job.question3) {
        return true
      }
      else {
        return false
      }
    },

    totalQuestions: function () {
      let count = 0
      
      if (this.hasQuestionnaire) {
        if(this.job.question1) {
          count++
        }

        if (this.job.question2) {
          count++
        }

        if (this.job.question3) {
          count++
        }
      }

      return count
    },

    validAnswers: function () {
      let count = 0
      
      if (this.answer.q1 && this.answer.q1.length <= 500) {
        count++
      }
      
      if (this.answer.q2 && this.answer.q2.length <= 500) {
        count++
      }
      
      if (this.answer.q3 && this.answer.q3.length <= 500) {
        count++
      }
      
      return this.totalQuestions == count
    },

    conflicts: function() {
      let conflicts = []
      
      this.availabilities.forEach(available => {
        if (this.job.contractDates && this.job.contractDates.length) {
          this.job.contractDates.forEach(dates => {
            if (available.color == 'red' && moment(available.startDate).valueOf() >= moment(dates[0]).valueOf() && moment(available.startDate).valueOf() <= moment(dates[1]).valueOf() ) {
              conflicts.push(this.$options.filters.formatDate(available.startDate))
            }
          })
        }
      })

      return _reverse(conflicts)
    }
  },
  
  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions("job", [
      "getSelectedJobById",
      "checkFavorite",
      "queryThisJob",
    ]),

    ...mapActions('jobs', [
      'deleteJob', 
      'saveNotes'
    ]),

    ...mapActions("favorite", [
      "storeFavorite"
    ]),

    ...mapActions("users", [
      "getUserByUid"
    ]),
    
    ...mapActions("profile", [
      "getProfile"
    ]),

    ...mapActions("apply", [
      "storeApplyJob",
      "getJobApplied",
      "allApplicants",
      "updateApplicantStatus",
    ]),

    favorite(favorite) {
      if (favorite) {
        Promise.all([
          this.storeFavorite({ update: true, favoriteObj: favorite }),
        ]).then(() => {
          this.checkFavorite(this.job)
        })
      } else {
        Promise.all([this.storeFavorite(this.job)]).then(() => {
          this.checkFavorite(this.job)
        })
      }
    },

    applied() {
      if (this.hasQuestionnaire) {
        this.job.answers = this.answer
      }

      if (this.profile.aboutMe && this.profile.phone && this.user.address) {
        Promise.all([this.storeApplyJob(this.job)])
        .then(() => {
          this.getJobApplied()
            setTimeout(() => {
              this.$router.push({
                name: "AppliedJob",
                params: { isApplied: true },
              })
            }, 500)
        })
      }
      else {
        this.errorDialog = true
      }
    },

    getJobStatus(job) {
      let data = []
      if (this.jobApplied) {
        data = _find(this.jobApplied, function (applied) {
          return applied.jobId == job.id
        })
      }
      return data.status
    },

    validateQuery() {
      if (this.$refs.queryForm.validate()) {
        let data = {
          jobId: this.job.id,
          schoolId: this.job.schoolId,
          Msg: this.queryTxt,
        }
        Promise.all([this.queryThisJob(data)]).then(() => {
          this.$refs.queryForm.reset()
        })
      }
    },


    getPDFurl(applicant) {
      var storage = firebase.storage()
      storage
        .ref(`contract/${applicant.contractFile}`)
        .getDownloadURL()
        .then((url) => {
          window.open(url, "_blank")
        })
        .catch((error) => {
          console.log(error.message)
          storage
            .ref(`contract/${applicant.contractFile}`)
            .getDownloadURL()
            .then((url) => {
              window.open(url, "_blank")
            })
        })
    },

    updateStatus(data) {
      if (data.action == "decline") {
        Promise.all([
          this.updateApplicantStatus({
            applicant: data.applicant,
            actions: this.jobStatusArray.declined,
          }),
        ]).then(() => {
          this.getSelectedJobById(this.$route.params.id)
          this.getJobApplied()
        })
      } else if (data.action == "hire") {
        Promise.all([
          this.updateApplicantStatus({
            applicant: data.applicant,
            actions: this.jobStatusArray.hired,
          }),
        ]).then(() => {
          this.getSelectedJobById(this.$route.params.id)
          this.getJobApplied()
        })
      }
    },

    searchUser() {
      let applicants = this.$store.getters["apply/getApplicantObjects"](
        this.job.id
      )
      applicants.forEach((applicant) => {
        this.getUserByUid(applicant.userid)
      })
    },

    saveNote(job) {
      Promise.all([this.saveNotes(job)])
      .then(() => this.editNote = false)
    },

    duplicateJob(job) {
      let data = _omit(job, ['ref', 'id', 'attachments'])
      this.$store.commit('job/setJob', data)
      this.$store.commit('job/duplicatingState', job.id)
      this.$router.push({ name: 'Job' })
    },

    confirmDelete() {
      this.deleteDialog = true
    },

    closeDeleteDialog() {
      this.deleteDialog = false
    },

    deleteConfirmed() {
      Promise.all([this.deleteJob(this.job)])
      .then(() => {
        this.closeDeleteDialog()
        this.$router.push({ name: 'MyJobs' })
      })
    },

    checkNotification() {
      let user = firebase.auth().currentUser

      db.collection('notifications').doc(user.uid)
      .collection('alerts').doc(this.$route.query.notification)
      .get().then(doc => {
        if (doc.exists) {
          this.$store.dispatch('notifications/deleteAlert', doc.data().source)
        }
      })
    },

    sortContractDates(contractDates) {
      return _orderBy(contractDates, (date) => {
        return moment(date[0]).valueOf()
      }, 'asc')
    },

    loadJobData() {
      this.$store.commit('job/clearSelectedSchool')
      this.getSelectedJobById({ id: this.$route.params.id, template: !!this.$route.query.template })
      this.getJobApplied()
      this.allApplicants()
      this.getProfile()
      this.$store.dispatch('availability/getUserAvailability')

      // IF NOTIFICATION
      if (this.$route.query.notification) this.checkNotification()
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.loadJobData()
  },
}
</script>

<style lang="scss">
.favorite {
  color: #ff0100 !important;
}

.label-font {
  font-weight: bold;
  color: var(--var-primary-base) !important;
}

.non-favorite {
  color: #9e9e9e !important;
}
</style>